/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; background-color: #FAF9F6; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


a {
	color: inherit;
	text-decoration: none;
}

h1,h2,h3,h4,h5 {
	display: block;
	margin-left: 0;
	margin-right: 0;
	// margin-top: 0.5em;
	// margin-bottom: .2em;
	font-weight: bold;
}

p {
	font-size: .9em;
	margin-top: .2em;
	margin-bottom: .5em;
}

.dg-xs .mat-dialog-container {
  padding: 0;
}

.dg-default .mat-dialog-container {
  padding: 10px;
}