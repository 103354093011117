// @use '~@angular/material' as mat;
@import '@angular/material/theming';
@include mat-core();


/* ======== angular material custom theme ======== */
// $isakaya-primary: mat-palette($mat-deep-purple);
$my-primary: mat-palette($mat-red, 500);
$my-christmas: mat-palette($mat-red, 500);
$my-accent: mat-palette($mat-yellow, 100, 500, A100);
$my-warn: mat-palette($mat-red);

// Light theme
$my-light-theme: mat-light-theme($my-primary, $my-accent, $my-warn);

// Dark theme
$my-dark-theme: mat-dark-theme($my-primary, $my-accent, $my-warn);

// Main theme defination
@include angular-material-theme($my-light-theme);
